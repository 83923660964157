@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

:root {
	--light-blue: #007bff;
	--light-green: #28a745;
	--light-red: #dc3545;
	--light-grey: #eeeeee;
}

#root {
	height: 100%;
}

*, *::before, *::after {
    box-sizing: inherit;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;

	-webkit-user-select: none; 
	-ms-user-select: none; 
	user-select: none; 
}

body {
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
}

a {
	text-decoration: none;
	color: white;
}

p {
  	line-height: 1.7;
}

ul {
  	list-style: none;
}

li {
  	line-height: 2.2;
}

h1,
h2,
h3 {
	font-weight: 600;
	margin-bottom: 10px;
}

.container {
	width: 100%;
	/* max-width: 960px; */
	margin: 0 auto;
}

.bodyWrapper {
	margin-top: 45px;
	padding: 30px;
}

.link {
	color: var(--light-blue);
	font-weight: 700;
}

.link:hover {
	border-bottom: 1px solid var(--light-blue);
}

.btn {
	display: inline-block;
	padding: 12px 20px;
	background-color: var(--light-grey);
	background-color: #777;
	color: white;
	border-radius: 5px;
	font-weight: 600;
    font-size: inherit;
	border: none;
	cursor: pointer;
	line-height: 1;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
}

.btn:hover {
	filter: brightness(70%);
}

.btn.btn-small {
	padding: 5px 10px;
}

.btn.btn-success {
	background-color: var(--light-green) !important;
}

.btn.btn-info {
	background-color: var(--light-blue) !important;
}

.btn.btn-danger {
	background-color: var(--light-red) !important;
}

.btn.btn-dark {
	background-color: black !important;
}

.btn.disabled {
    opacity: 0.2;
	cursor: initial;
}

.form {
	width: 100%;
	max-width: 50%;
	margin: auto;
}

.form-group {
	margin: 0 0 10px;
}

.form-label {
	font-weight: 600;
	display: inline-block;
	width: 40%;
}

.form-control {
	background-color: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0);
	padding: 10px;
    font-size: inherit;
    font-family: inherit;
    border-radius: 4px;    
	width: 100%;
	display: inline-block;
	width: 60%;
}

.table {
	width: 100%;
    margin-bottom: 1rem;
    border-collapse: collapse;
	text-align: left;
}

.table tr.success th, .table tr.success td  {
	background-color: rgba(40, 167, 69, 0.5);
} 

.table tr.info th, .table tr.info td  {
	background-color: rgba(0, 123, 255, 0.5);
} 

.table tr.danger th, .table tr.danger td  {
	background-color: rgba(220, 53, 69, 0.5);
} 

.table th {
    background-color: var(--light-grey);
    font-weight: 600;
	padding: 10px;
    vertical-align: middle;
    text-align: inherit;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.table th.sort-column {
	cursor: pointer;
}

.table td {
    /* background-color: #343a40; */
	padding: 10px;
    vertical-align: middle;
    text-align: inherit;
    border: 1px solid rgba(0, 0, 0, 0.05);
	font-size: 13px;
}
.table .btn {
	/* width: 100%; */
	/* margin-bottom: 5px; */
	display: inline-block;
	padding: 3px 4px;
	margin-right: 5px;
}
.table .btn:last-child {
	margin-right: 0;
}

.badge {
	display: inline-block;
    padding: 0 4px;
    font-size: 10px; 
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 5px;
    color: white;
	margin-left: 5px;
	padding: 5px 5px 3px;
	line-height: 1;
} 

.badge.badge-success {
	background-color: var(--light-green);
} 

.badge.badge-danger {
	background-color: var(--light-red);
}

.badge.badge-normal {
	background-color: var(--light-grey);
}

.badge.badge-info {
	background-color: var(--light-blue);
}

.badge.badge-large {
    font-size: 13px; 
	font-weight: 600;
}

.header {
	background-color: var(--light-grey);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	text-align: left;
}

.headerMenuItem {
	display: inline-block;
}

.headerMenuItem a {
	display: block;
	color: inherit;
	padding: 7.5px 15px;
	font-size: 14px;
}

.headerMenuItem a:hover {
	background-color: #eee;
	/* color: white; */
}

.headerMenuItem.active a {
	background-color: var(--light-blue);
	color: white;
	font-weight: 600;
}

.pageTitle {
	font-size: 35px;
	line-height: 1;
	font-weight: 600;
	letter-spacing: 0;
	margin: 0 0 30px;
	text-align: left;
}


.loginSection {
	width: 100%;
	max-width: 250px;
	margin: 0 auto;
	text-align: center;
}

.loginSection .form-control {
	width: 100%;
	max-width: 100%;
}

.logo {
	width: 100%;
	max-width: 200px;
	height: auto;
	display: inline-block;
	margin-bottom: 30px;
}